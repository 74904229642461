<template>
  <v-container
    fluid
    class="light-blue lighten-5 pb-16 px-1"
  >
    <v-row class="mb-0 d-flex justify-center align-center">
      <v-col
        cols="12"
        sm="12"
        md="9"
        lg="9"
        class="pb-0 mb-0"
      >
        <v-card class="white px-auto pb-0 mb-0">
          <v-stepper>
            <v-stepper-header>
              <v-stepper-step
                step="1"
                complete
                complete-icon="mdi-home-circle"
              >
                CONDO INFO
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                step="2"
                complete
                complete-icon="mdi-home-circle"
              >
                CHOOSE PLAN
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                step="3"
                complete
                complete-icon="mdi-home-circle"
              >
                PROPERTY INFO
              </v-stepper-step>

              <v-divider></v-divider>
              <v-stepper-step step="4">
                COVERAGES
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="5">
                PURCHASE PLAN
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step step="6">
                YOUR POLICY
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col
        cols="12"
        sm="12"
        md="9"
        lg="9"
        class="mt-0 pt-1"
      >
        <v-card class="mt-0 mb-16">
          <validation-observer
            ref="observer"
            v-slot="{ invalid }"
          >
            <v-form
              ref="form"
              class="px-8 font-weight-bold"
              @submit.prevent="next()"
            >
              <v-row>
                <v-col>
                  <div class="title-text text-center mt-2">
                    Property Info
                  </div>
                </v-col>
              </v-row>
              <!------------------------Select: Details ----------------------------->
              <v-row>
                <v-col>
                  <div class="sub-title mt-2">
                    Options
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                  lg="4"
                >
                  <p>Policy Start Date</p>
                  <v-dialog
                    ref="startDateDialog"
                    v-model="startDateDialog"
                    :return-value.sync="policy.startDate"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Start Date"
                        rules="required"
                      >
                        <v-text-field
                          v-model="policy.startDate"
                          outlined
                          label="Policy Start Date"
                          :error-messages="errors"
                          readonly
                          v-bind="attrs"
                          prepend-icon="mdi-calendar"
                          v-on="on"
                          @click="
                            clickedFieldGlobal(
                              'propertyInfo policyStarDate',
                              49,
                              'Condo Survey v1'
                            )
                          "
                        ></v-text-field>
                      </validation-provider>
                    </template>
                    <v-date-picker
                      v-model="policy.startDate"
                      scrollable
                      :min="todaysDate"
                      :max="maxDate"
                      color="secondary"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="
                          startDateDialog = false
                          clickedFieldGlobal(
                            'propertyInfo policyStartDateCancelBtn',
                            50,
                            'Condo Survey v1'
                          )
                        "
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="
                          $refs.startDateDialog.save(policy.startDate)
                          clickedFieldGlobal(
                            'propertyInfo policyStarDateOkBtn',
                            51,
                            'Condo Survey v1'
                          )
                        "
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="SSN"
                    rules="required|min:11|max:11"
                  >
                    <v-text-field
                      v-model="actualSSN"
                      v-mask="'###-##-####'"
                      outlined
                      label="Social Security Number"
                      color="primary"
                      :error-messages="errors"
                    />
                  </validation-provider>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Existing Policy"
                    rules="required"
                  >
                    <p>
                      Do you have an existing policy with Stillwater Insurance?
                    </p>
                    <v-btn-toggle
                      v-model="existingPolicy"
                      :error-messages="errors"
                    >
                      <v-btn
                        color="blanketbutton"
                        text
                        outlined
                        :value="true"
                        @click="
                          clickedFieldGlobal(
                            'propertyInfo existingPolicy - yes',
                            52,
                            'Renters Survey v1'
                          )
                        "
                      >
                        Yes
                      </v-btn>
                      <v-btn
                        color="blanketbutton"
                        text
                        outlined
                        :value="false"
                        @click="
                          clickedFieldGlobal(
                            'propertyInfo existingPolicy - no',
                            52,
                            'Renters Survey v1'
                          )
                        "
                      >
                        No
                      </v-btn>
                    </v-btn-toggle>
                  </validation-provider>
                </v-col>
              </v-row>
              <div v-if="existingPolicy">
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                  class="pl-0"
                >
                  <p>Existing Stillwater Policy Number</p>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Policy Number"
                    rules="required|alpha_num"
                  >
                    <v-text-field
                      v-model="existingPolicyInfo.policyNumber"
                      outlined
                      label="Policy Number"
                      :error-messages="errors"
                      @click="
                        clickedFieldGlobal(
                          'propertyInfo existingPolicyInfo.policyNumber',
                          53,
                          'Renters Survey v1'
                        )
                      "
                    ></v-text-field>
                  </validation-provider>
                  <p>
                    If you have an
                    <a
                      class="primary--text text-decoration-underline"
                      @click="
                        policyDialog = true
                        clickedFieldGlobal(
                          'propertyInfo policyDialog',
                          54,
                          'Condo Survey v1'
                        )
                      "
                    >existing policy with Sillwater</a>, please enter the policy number
                  </p>
                  <p>Type</p>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Type"
                    rules="required"
                  >
                    <v-select
                      v-model="existingPolicyInfo.LOBCd"
                      outlined
                      label="Type"
                      :items="lob"
                      return-object
                      item-text="txt"
                      item-value="value"
                      :error-messages="errors"
                      @click="
                        clickedFieldGlobal(
                          'propertyInfo existingPolicyInfo.LOBCd',
                          55,
                          'Renters Survey v1'
                        )
                      "
                    ></v-select>
                  </validation-provider>
                  <p>Coverage</p>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Coverage"
                    rules="required"
                  >
                    <v-select
                      v-model="existingPolicyInfo.policyCd"
                      outlined
                      label="Coverage"
                      :items="policyCd"
                      return-object
                      item-text="txt"
                      item-value="value"
                      :error-messages="errors"
                      @click="
                        clickedFieldGlobal(
                          'propertyInfo existingPolicyInfo.policyCd',
                          56,
                          'Renters Survey v1'
                        )
                      "
                    ></v-select>
                  </validation-provider>
                </v-col>
              </div>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <p>
                    Year Purchased
                  </p>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Year Purchased"
                    rules="required"
                  >
                    <v-autocomplete
                      v-model="yearPurchased"
                      :error-messages="errors"
                      :items="purchaseYears"
                      outlined
                      @click="
                        clickedFieldGlobal(
                          'propertyInfo yearPurchased',
                          57,
                          'Condo Survey v1'
                        )
                      "
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <p>
                    Livable Square Footage (excluding basement)
                    <v-tooltip
                      top
                      color="secondary"
                      max-width="250px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          dark
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-help-circle
                        </v-icon>
                      </template>
                      <span>The number of finished square feet in the structure.
                        "Finished" includes all living space (including closets
                        and stairwells) that is heated and/or cooled, has
                        finished walls and ceilings, and has floor covering.
                        This does not include unfinished basement and garage
                        areas.</span>
                    </v-tooltip>
                  </p>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Square Footage"
                    rules="required"
                  >
                    <v-text-field
                      v-model="squareFootage"
                      v-mask="'#######'"
                      :error-messages="errors"
                      outlined
                      @click="
                        clickedFieldGlobal(
                          'propertyInfo squareFootage',
                          58,
                          'Condo Survey v1'
                        )
                      "
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <!-- <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <p>Contents Coverage Amount</p>
                  <v-text-field
                    v-model="contentsCoverage"
                    disabled
                    outlined
                    class="mb-n4 pb-n2"
                  />
                  <a
                    class="primary--text text-decoration-underline"
                    @click="coverageIncreaseDialog = true"
                  >Increase this amount</a>
                </v-col>
              </v-row> -->
              <!-- <v-dialog
                v-model="coverageIncreaseDialog"
                transition="dialog-bottom-transition"
                max-width="800"
                persistent
              >
                <validation-observer
                  ref="observer2"
                  v-slot="{ invalid }"
                >
                  <v-card class="pa-4">
                    <v-row>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        lg="6"
                      >
                        <div class="secondary--text font-weight-bold text-h3 d-flex justify-start pa-2">
                          Replacement Cost
                        </div>
                        <div class="font-weight-bold pa-2">
                          Current Amount
                        </div>
                        <v-text-field
                          v-model="contentsCoverage"
                          outlined
                          disabled
                          class="pa-2"
                        />
                        <div class="font-weight-bold pa-2">
                          Change amount to:
                        </div>
                        <div class="d-inline-flex align-baseline">
                          <validation-provider
                            v-slot="{ errors }"
                            name="New Coverage Amount"
                            rules="numeric|min_value:15001|max_value:150000"
                          >
                            <v-text-field
                              v-model.number="newCoverageAmount"
                              outlined
                              class="pa-2"
                              :error-messages="errors"
                            />
                          </validation-provider>
                          <v-btn
                            :disabled="newCoverageAmount < 15001 || newCoverageAmount > 150000"
                            color="primary"
                            class="font-weight-bold"
                            @click="getCustomQuote"
                          >
                            Get Quote
                          </v-btn>
                        </div>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        lg="6"
                      >
                        <div class="secondary--text font-weight-bold text-h3 d-flex justify-center pa-2">
                          New Quote
                        </div>
                        <v-card
                          v-if="customQuote"
                          class="pa-4 primary--text mb-4"
                          elevation="6"
                        >
                          <div class="plan-title text-center font-weight-bold">
                            ${{ customQuotePremium }}/yr
                          </div>
                          <div class="plan-sub text-center font-weight-bold">
                            Payment Plans Available
                          </div>
                          <v-divider
                            class="my-2 primary"
                          ></v-divider>
                          <v-list-item
                            three-line
                          >
                            <v-list-item-content>
                              <v-list-item-title class="font-weight-bold">
                                ${{ customQuoteDeductible }} Deductible
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                This is what you will pay out of pocket if you have a claim
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item
                            two-line
                          >
                            <v-list-item-content>
                              <v-list-item-title class="font-weight-bold">
                                $ {{ customQuotePPR }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                Personal Property Replacement Cost
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item
                            two-line
                          >
                            <v-list-item-content>
                              <v-list-item-title class="font-weight-bold">
                                $ {{ customQuoteLiability }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                Liability
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-card>
                        <v-row>
                          <v-col>
                            <v-progress-linear
                              :active="loading"
                              :indeterminate="loading"
                              absolute
                              color="primary"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-card-actions class="d-flex justify-center">
                      <v-btn
                        color="secondary"
                        class="font-weight-bold"
                        @click="closeCoverageIncreaseDialog"
                      >
                        Close
                      </v-btn>
                      <v-btn
                        :disabled="customQuote === null"
                        color="primary"
                        class="font-weight-bold"
                        @click="submitCoverageIncrease"
                      >
                        Submit
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </validation-observer>
              </v-dialog> -->
              <v-row>
                <v-col>
                  <div class="sub-title mt-2">
                    Your Property
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div
                    v-for="(question, index) in questions"
                    :key="question.id"
                    :index="index"
                  >
                    <div
                      v-if="
                        !question.dependentRule &&
                          !manualQuestions.includes(question.id)
                      "
                    >
                      <p>
                        {{ question.question }}
                      </p>
                      <validation-provider
                        v-slot="{ errors }"
                        name="above"
                        rules="required"
                      >
                        <v-btn-toggle
                          v-if="question.answers.type === 'Y/N'"
                          v-model="answers[index]"
                          class="mb-4"
                          :error-messages="errors"
                        >
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            :value="question.answers.values[0].value"
                            @click="
                              setAnswer(
                                question.qCode,
                                question.answers.values[0].value,
                                question.id
                              )
                              clickedFieldGlobal(
                                `coverageQuestions ${index} - yes`,
                                59,
                                'Condo Survey v1'
                              )
                            "
                          >
                            {{ question.answers.values[0].txt }}
                          </v-btn>
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            :value="question.answers.values[1].value"
                            @click="
                              setAnswer(
                                question.qCode,
                                question.answers.values[1].value,
                                question.id
                              )
                              clickedFieldGlobal(
                                `coverageQuestions ${index} - no`,
                                59,
                                'Condo Survey v1'
                              )
                            "
                          >
                            {{ question.answers.values[1].txt }}
                          </v-btn>
                        </v-btn-toggle>
                      </validation-provider>
                      <div
                        v-if="
                          question.answers.type === 'select' &&
                            question.id !== '1820' &&
                            question.id !== '1812'
                        "
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="above"
                          rules="required"
                        >
                          <v-select
                            v-model="answers[index]"
                            :items="question.answers.values"
                            :error-messages="errors"
                            outlined
                            item-value="value"
                            item-text="txt"
                            return-object
                            @change="
                              setAnswer(
                                question.qCode,
                                answers[index].value,
                                question.id
                              )
                            "
                            @click="
                              clickedFieldGlobal(
                                `coverageQuestions ${index}`,
                                60,
                                'Condo Survey v1'
                              )
                            "
                          >
                          </v-select>
                        </validation-provider>
                      </div>
                    </div>
                    <div
                      v-if="
                        question.dependentRule &&
                          question.dependentRule.action === 'DISPLAY' &&
                          searchList(question) &&
                          question.answers.type === 'Y/N' &&
                          !manualQuestions.includes(question.id)
                      "
                    >
                      <p>{{ question.question }}</p>
                      <validation-provider
                        v-slot="{ errors }"
                        name="above"
                        rules="required"
                      >
                        <v-btn-toggle
                          v-model="answers[index]"
                          class="mb-4"
                          :error-messages="errors"
                        >
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            :value="question.answers.values[0].value"
                            @click="
                              setAnswer(
                                question.qCode,
                                question.answers.values[0].value,
                                question.id
                              )
                              clickedFieldGlobal(
                                `coverageQuestions ${index} - yes`,
                                61,
                                'Condo Survey v1'
                              )
                            "
                          >
                            {{ question.answers.values[0].txt }}
                          </v-btn>
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            :value="question.answers.values[1].value"
                            @click="
                              setAnswer(
                                question.qCode,
                                question.answers.values[1].value,
                                question.id
                              )
                              clickedFieldGlobal(
                                `coverageQuestions ${index} - no`,
                                61,
                                'Condo Survey v1'
                              )
                            "
                          >
                            {{ question.answers.values[1].txt }}
                          </v-btn>
                        </v-btn-toggle>
                      </validation-provider>
                    </div>
                    <div
                      v-if="
                        question.dependentRule &&
                          question.dependentRule.action === 'DISPLAY' &&
                          searchList(question) &&
                          question.answers.type === 'integer' &&
                          !manualQuestions.includes(question.id)
                      "
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Amount"
                        rules="required|numeric"
                      >
                        <p>{{ question.question }}</p>
                        <v-text-field
                          v-model.number="answers[index]"
                          v-mask="'#########'"
                          outlined
                          color="primary"
                          :error-messages="errors"
                          @blur="
                            setAnswer(
                              question.qCode,
                              answers[index],
                              question.id
                            )
                          "
                          @click="
                            clickedFieldGlobal(
                              `coverageQuestions ${index}`,
                              62,
                              'Condo Survey v1'
                            )
                          "
                        ></v-text-field>
                      </validation-provider>
                      <div
                        v-if="
                          answers[index] > 0 &&
                            question.question.includes('Liability Related')
                        "
                      >
                        <div
                          v-for="(n, i) in answers[index]"
                          :key="i"
                        >
                          <loss-component
                            :id="`LIABILITYLOSS${n}`"
                            :n="n"
                            type="Liability"
                            location-ref="LOC1"
                            loss-cause="Liability"
                            :loss-codes="lossCodes"
                            :click-field-values="
                              lossComponents.liabilityLosses.clickFieldValues
                            "
                            :sequence-numbers="
                              lossComponents.liabilityLosses.sequenceNumbers
                            "
                            :survey-type="surveyType"
                            @record-loss="recordLoss"
                          ></loss-component>
                        </div>
                      </div>
                      <div
                        v-if="
                          answers[index] > 0 &&
                            question.question.includes('Water Related')
                        "
                      >
                        <div
                          v-for="(n, i) in answers[index]"
                          :key="i"
                        >
                          <loss-component
                            :id="`WATERLOSS${n}`"
                            :n="n"
                            type="Water"
                            location-ref="LOC1"
                            loss-cause="Water"
                            :loss-codes="lossCodes"
                            :click-field-values="
                              lossComponents.waterLosses.clickFieldValues
                            "
                            :sequence-numbers="
                              lossComponents.waterLosses.sequenceNumbers
                            "
                            :survey-type="surveyType"
                            @record-loss="recordLoss"
                          ></loss-component>
                        </div>
                      </div>
                      <div
                        v-if="
                          answers[index] > 0 &&
                            question.question.includes('Appliance Related')
                        "
                      >
                        <div
                          v-for="(n, i) in answers[index]"
                          :key="i"
                        >
                          <loss-component
                            :id="`APPLIANCELOSS${n}`"
                            :n="n"
                            type="Appliance"
                            location-ref="LOC1"
                            loss-cause="Appliance-Water"
                            :loss-codes="lossCodes"
                            :click-field-values="
                              lossComponents.applianceLosses.clickFieldValues
                            "
                            :sequence-numbers="
                              lossComponents.applianceLosses.sequenceNumbers
                            "
                            :survey-type="surveyType"
                            @record-loss="recordLoss"
                          ></loss-component>
                        </div>
                      </div>
                      <div
                        v-if="
                          answers[index] > 0 &&
                            question.question.includes('Weather Related')
                        "
                      >
                        <div
                          v-for="(n, i) in answers[index]"
                          :key="i"
                        >
                          <loss-component
                            :id="`WEATHERLOSS${n}`"
                            :n="n"
                            type="Weather"
                            location-ref="LOC1"
                            loss-cause="Weather"
                            :loss-codes="lossCodes"
                            :click-field-values="
                              lossComponents.weatherLosses.clickFieldValues
                            "
                            :sequence-numbers="
                              lossComponents.weatherLosses.sequenceNumbers
                            "
                            :survey-type="surveyType"
                            @record-loss="recordLoss"
                          ></loss-component>
                        </div>
                      </div>
                      <div
                        v-if="
                          answers[index] > 0 &&
                            question.question.includes('Other Losses')
                        "
                      >
                        <div
                          v-for="(n, i) in answers[index]"
                          :key="i"
                        >
                          <loss-component
                            :id="`OTHERLOSS${n}`"
                            :n="n"
                            type="Other"
                            location-ref="LOC1"
                            loss-cause="Other"
                            :loss-codes="lossCodes"
                            :click-field-values="
                              lossComponents.otherLosses.clickFieldValues
                            "
                            :sequence-numbers="
                              lossComponents.otherLosses.sequenceNumbers
                            "
                            :survey-type="surveyType"
                            @record-loss="recordLoss"
                          ></loss-component>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="question.id === '1820' || question.id === '1812'"
                    >
                      <p>{{ question.question }}</p>
                      <validation-provider
                        v-slot="{ errors }"
                        name="above"
                        rules="required"
                      >
                        <v-select
                          v-model="solidFuel"
                          :items="question.answers.values"
                          :error-messages="errors"
                          outlined
                          item-value="value"
                          item-text="txt"
                          @click="
                            clickedFieldGlobal(
                              `propertyInfo solidFuel`,
                              78,
                              'Condo Survey v1'
                            )
                          "
                        >
                        </v-select>
                      </validation-provider>
                    </div>
                    <div v-if="question.id === '2350'">
                      <p>{{ question.question }}</p>
                      <validation-provider
                        v-slot="{ errors }"
                        name="above"
                        rules="required"
                      >
                        <v-select
                          v-model="q2350"
                          :items="question.answers.values"
                          :error-messages="errors"
                          outlined
                          item-value="value"
                          item-text="txt"
                          @click="
                            clickedFieldGlobal(
                              `propertyInfo question2350`,
                              79,
                              'Condo Survey v1'
                            )
                          "
                        >
                        </v-select>
                      </validation-provider>
                    </div>
                    <div v-if="question.id === '2057'">
                      <p>{{ question.question }}</p>
                      <validation-provider
                        v-slot="{ errors }"
                        name="above"
                        rules="required"
                      >
                        <v-btn-toggle
                          v-model="q2057"
                          class="mb-4"
                          :error-messages="errors"
                          @change="log2057"
                        >
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            :value="question.answers.values[0].value"
                            @click="
                              clickedFieldGlobal(
                                `propertyInfo question2057 -yes`,
                                80,
                                'Condo Survey v1'
                              )
                            "
                          >
                            {{ question.answers.values[0].txt }}
                          </v-btn>
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            :value="question.answers.values[1].value"
                            @click="
                              clickedFieldGlobal(
                                `propertyInfo question2057 -no`,
                                80,
                                'Condo Survey v1'
                              )
                            "
                          >
                            {{ question.answers.values[1].txt }}
                          </v-btn>
                        </v-btn-toggle>
                      </validation-provider>
                    </div>
                    <div v-if="question.id === '2101' && q2057 === '1500'">
                      <p>{{ question.question }}</p>
                      <validation-provider
                        v-slot="{ errors }"
                        name="above"
                        rules="required"
                      >
                        <v-btn-toggle
                          v-model="alternativeWaterSource"
                          class="mb-4"
                          :error-messages="errors"
                          @change="
                            setAnswer(
                              question.qCode,
                              alternativeWaterSource,
                              question.id
                            )
                          "
                        >
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            :value="question.answers.values[0].value"
                            @click="
                              clickedFieldGlobal(
                                `propertyInfo alternativeWaterSource -yes`,
                                81,
                                'Condo Survey v1'
                              )
                            "
                          >
                            {{ question.answers.values[0].txt }}
                          </v-btn>
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            :value="question.answers.values[1].value"
                            @click="
                              clickedFieldGlobal(
                                `propertyInfo alternativeWaterSource -no`,
                                81,
                                'Condo Survey v1'
                              )
                            "
                          >
                            {{ question.answers.values[1].txt }}
                          </v-btn>
                        </v-btn-toggle>
                      </validation-provider>
                    </div>
                    <div v-if="question.id === '4445' && yearBuiltDiff > 50">
                      <p>{{ question.question }}</p>
                      <validation-provider
                        v-slot="{ errors }"
                        name="Year Built"
                        rules="required"
                      >
                        <v-autocomplete
                          v-model="plumbingUpdated"
                          :items="plumbingYears"
                          :error-messages="errors"
                          outlined
                          @change="
                            logConstruction()
                            setAnswer(
                              question.qCode,
                              plumbingUpdated,
                              question.id
                            )
                          "
                          @click="
                            clickedFieldGlobal(
                              `propertyInfo plumbingUpdated`,
                              82,
                              'Condo Survey v1'
                            )
                          "
                        />
                      </validation-provider>
                    </div>
                    <div v-if="question.id === '4460' && yearBuiltDiff > 20">
                      <p>{{ question.question }}</p>
                      <validation-provider
                        v-slot="{ errors }"
                        name="Year Built"
                        rules="required"
                      >
                        <v-autocomplete
                          v-model="roofUpdated"
                          :items="plumbingYears"
                          :error-messages="errors"
                          outlined
                          @change="
                            logConstruction()
                            setAnswer(question.qCode, roofUpdated, question.id)
                          "
                          @click="
                            clickedFieldGlobal(
                              `propertyInfo roofUpdated`,
                              83,
                              'Condo Survey v1'
                            )
                          "
                        />
                      </validation-provider>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div class="sub-title mt-2">
                    Discounts
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="11"
                  md="9"
                >
                  <div
                    v-for="(question, index) in discountQuestions"
                    :key="question.id"
                    :index="index"
                  >
                    <div v-if="question.answers.type === 'custom'">
                      <p>{{ question.question }}</p>
                      <validation-provider
                        v-slot="{ errors }"
                        name="above"
                        rules="required"
                      >
                        <v-btn-toggle
                          v-model="discountAnswersList[question.id]"
                          :error-messages="errors"
                          class="mb-4"
                        >
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            @click="
                              setCustomDiscountAnswer(
                                question.jCode,
                                'YES',
                                question.id
                              )
                              clickedFieldGlobal(
                                `coverageQuestions ${index} - yes`,
                                84,
                                'Condo Survey v1'
                              )
                            "
                          >
                            Yes
                          </v-btn>
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            @click="
                              setCustomDiscountAnswer(
                                question.jCode,
                                'NO',
                                question.id
                              )
                              clickedFieldGlobal(
                                `coverageQuestions ${index} - no`,
                                84,
                                'Condo Survey v1'
                              )
                            "
                          >
                            No
                          </v-btn>
                        </v-btn-toggle>
                      </validation-provider>
                    </div>
                    <div v-if="question.id === '2000'">
                      <p>{{ question.question }}</p>
                      <validation-provider
                        v-slot="{ errors }"
                        name="above"
                        rules="required"
                      >
                        <!-- <v-btn-toggle
                          v-model="discountAnswersList[question.id]"
                          :error-messages="errors"
                          class="mb-4"
                        >
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            @click="setCustomDiscountAnswer(question.jCode, 'YES', question.id)"
                          >
                            Yes
                          </v-btn>
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            @click="setCustomDiscountAnswer(question.jCode, 'NO', question.id)"
                          >
                            No
                          </v-btn>
                        </v-btn-toggle> -->
                        <v-select
                          v-model="discountAnswersList[question.id]"
                          :items="question.answers.values"
                          :error-messages="errors"
                          outlined
                          item-value="value"
                          item-text="txt"
                          return-object
                          @change="
                            sprinklerCd = discountAnswersList[question.id].value
                          "
                          @click="
                            clickedFieldGlobal(
                              `coverageQuestions ${index}`,
                              85,
                              'Condo Survey v1'
                            )
                          "
                        >
                        </v-select>
                      </validation-provider>
                    </div>
                    <div
                      v-if="
                        question.answers.type === 'select' &&
                          question.id !== '2000' &&
                          question.id !== '1205' &&
                          question.id !== '4965'
                      "
                    >
                      <p>{{ question.question }}</p>
                      <validation-provider
                        v-slot="{ errors }"
                        name="above"
                        rules="required"
                      >
                        <v-select
                          v-model="discountAnswersList[question.id]"
                          :items="question.answers.values"
                          :error-messages="errors"
                          outlined
                          item-value="value"
                          item-text="txt"
                          return-object
                          @change="
                            setDiscountAnswer(
                              question.jCode[0],
                              discountAnswersList[question.id].value,
                              question.id
                            )
                          "
                          @click="
                            clickedFieldGlobal(
                              `coverageQuestions ${index}`,
                              86,
                              'Condo Survey v1'
                            )
                          "
                        >
                        </v-select>
                      </validation-provider>
                    </div>
                    <div v-if="question.id === '1205'">
                      <p>{{ question.question }}</p>
                      <validation-provider
                        v-slot="{ errors }"
                        name="above"
                        rules="required"
                      >
                        <v-select
                          v-model="roofType"
                          :items="question.answers.values"
                          :error-messages="errors"
                          outlined
                          item-value="value"
                          item-text="txt"
                          return-object
                          @click="
                            clickedFieldGlobal(
                              `coverageQuestions ${index}`,
                              87,
                              'Condo Survey v1'
                            )
                          "
                        >
                        </v-select>
                      </validation-provider>
                    </div>
                    <div v-if="question.id === '4563'">
                      <p>{{ question.question }}</p>
                      <validation-provider
                        v-slot="{ errors }"
                        name="above"
                        rules="required"
                      >
                        <v-btn-toggle
                          v-model="stormShutters"
                          :error-messages="errors"
                          class="mb-4"
                        >
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="B"
                            @click="
                              clickedFieldGlobal(
                                `coverageQuestions ${index} - yes`,
                                88,
                                'Condo Survey v1'
                              )
                            "
                          >
                            Yes
                          </v-btn>
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="NO"
                            @click="
                              clickedFieldGlobal(
                                `coverageQuestions ${index} - no`,
                                88,
                                'Condo Survey v1'
                              )
                            "
                          >
                            No
                          </v-btn>
                        </v-btn-toggle>
                      </validation-provider>
                    </div>
                    <!-- <div v-if="question.answers.type === 'Y/N' && question.id != '9099' && question.id != '1150'">
                      <p> {{ question.question }}</p>
                      <validation-provider
                        v-slot="{ errors }"
                        name="above"
                        rules="required"
                      >
                        <v-btn-toggle
                          v-model="discountAnswersList[question.id]"
                          :error-messages="errors"
                          class="mb-4"
                        >
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            :value="question.answers.values[0].value"
                            @click="setDiscountAnswer(question.jCode[0], question.answers.values[0].value, question.id), clickedField(`coverageQuestions ${index} - yes`, 31)"
                          >
                            {{ question.answers.values[0].txt }}
                          </v-btn>
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            :value="question.answers.values[1].value"
                            @click="setDiscountAnswer(question.jCode[0], question.answers.values[1].value, question.id), clickedField(`coverageQuestions ${index} - no`, 31)"
                          >
                            {{ question.answers.values[1].txt }}
                          </v-btn>
                        </v-btn-toggle>
                      </validation-provider>
                    </div> -->
                    <div v-if="question.id === '9099'">
                      <p>{{ question.question }}</p>
                      <validation-provider
                        v-slot="{ errors }"
                        name="above"
                        rules="required"
                      >
                        <v-btn-toggle
                          v-model="discountAnswersList[question.id]"
                          :error-messages="errors"
                          class="mb-4"
                        >
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            :value="question.answers.values[0].value"
                            @click="
                              setAnswer(
                                question.qCode,
                                question.answers.values[0].value,
                                question.id
                              )
                              clickedFieldGlobal(
                                `coverageQuestions ${index} - yes`,
                                89,
                                'Condo Survey v1'
                              )
                            "
                          >
                            Yes
                          </v-btn>
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            :value="question.answers.values[1].value"
                            @click="
                              setAnswer(
                                question.qCode,
                                question.answers.values[1].value,
                                question.id
                              )
                              clickedFieldGlobal(
                                `coverageQuestions ${index} - no`,
                                89,
                                'Condo Survey v1'
                              )
                            "
                          >
                            No
                          </v-btn>
                        </v-btn-toggle>
                      </validation-provider>
                    </div>
                    <div v-if="question.id === '1150'">
                      <p>{{ question.question }}</p>
                      <validation-provider
                        v-slot="{ errors }"
                        name="above"
                        rules="required"
                      >
                        <v-btn-toggle
                          v-model="discountAnswersList[index]"
                          :error-messages="errors"
                          class="mb-4"
                        >
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            :value="question.answers.values[0].value"
                            @click="
                              setAnswer(
                                question.qCode,
                                question.answers.values[0].value,
                                question.id
                              )
                              clickedFieldGlobal(
                                `coverageQuestions ${index} - yes`,
                                90,
                                'Condo Survey v1'
                              )
                            "
                          >
                            Yes
                          </v-btn>
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            :value="question.answers.values[1].value"
                            @click="
                              setAnswer(
                                question.qCode,
                                question.answers.values[1].value,
                                question.id
                              )
                              clickedFieldGlobal(
                                `coverageQuestions ${index} - yes`,
                                90,
                                'Condo Survey v1'
                              )
                            "
                          >
                            No
                          </v-btn>
                        </v-btn-toggle>
                      </validation-provider>
                    </div>
                    <div v-if="question.id === '4965'">
                      <p>{{ question.question }}</p>
                      <validation-provider
                        v-slot="{ errors }"
                        name="above"
                        rules="required"
                      >
                        <v-select
                          v-model="maritalStatus"
                          :items="question.answers.values"
                          :error-messages="errors"
                          outlined
                          item-value="value"
                          item-text="txt"
                          @click="
                            clickedFieldGlobal(
                              `coverageQuestions ${index}`,
                              92,
                              'Condo Survey v1'
                            )
                          "
                        >
                        </v-select>
                      </validation-provider>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row
                v-if="loading"
                class="mt-12"
              >
                <v-col class="d-flex justify-center">
                  <ellipse-progress
                    :loading="loading"
                    :size="180"
                    img-src="src/assets/images/Condo-Icon.png"
                    thickness="5"
                    empty-thickness="3"
                    line-mode="out 5"
                    legend="false"
                    animation="rs 700 1000"
                    font-size="1.5rem"
                    color="#00A1B7"
                    empty-color-fill="transparent"
                    filename="Condo-Icon.png"
                  >
                  </ellipse-progress>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div class="d-flex justify-end">
                    <v-btn
                      x-large
                      color="primary"
                      class="mb-2 font-weight-bold"
                      :disabled="invalid || loading"
                      @click="
                        next()
                        clickedFieldGlobal(
                          `propertyInfo next`,
                          93,
                          'Condo Survey v1'
                        )
                      "
                    >
                      Next
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </validation-observer>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="policyDialog"
      transition="dialog-bottom-transition"
      max-width="600"
    >
      <v-card>
        <v-card-text>
          <div class="text-h4 pa-4">
            Your policy number can be found on the top right corner of your
            invoice or on your policy declaration page.
          </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn
            color="primary"
            text
            x-large
            @click="policyDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import moment from 'moment'
  import numeral from 'numeral'
  import lossComponent from '../components/Stillwater/LossComponent'
  import EllipseProgress from '../components/EllipseProgress.vue'
  export default {
    components: {
      lossComponent,
      EllipseProgress,
    },
    data () {
      return {
        actualSSN: null,
        lengthUnoccupied: '',
        alternativeWaterSource: '',
        maritalStatus: '',
        unoccupiedMonths: '',
        stormShutters: '',
        roofType: '',
        roofUpdated: '',
        plumbingUpdated: '',
        solidFuel: '',
        q2057: '',
        q2350: '',
        manualQuestions: ['1820', '2057', '2350', '1812'],
        squareFootage: '',
        yearBuilt: '',
        monthPurchased: '',
        yearPurchased: '',
        months: [
          '01',
          '02',
          '03',
          '04',
          '05',
          '06',
          '07',
          '08',
          '09',
          '10',
          '11',
          '12',
        ],
        existingPolicyInfo: {
          policyNumber: '',
          policyCd: '',
          LOBCd: '',
        },
        surveyAnswers: new Map(),
        sprinklerCd: '',
        liabilityLosses: {
          dates: [],
          amounts: [],
          lossCauseCd: 'Liability',
          claimStatusCodes: [],
        },
        fireExtinguisherInd: '',
        lossAmounts: [],
        losses: [],
        recordedLosses: [],
        question9099: 'NO',
        discountAnswersList: {},
        discountAnswers: [],
        customDiscountAnswers: [],
        answers: [],
        answerList: [],
        custom: '',
        newCoverageAmount: '',
        coverageIncreaseDialog: false,
        constructionTypeDialog: false,
        constructionTypes: [
          { txt: 'Masonry, Brick or Stone', value: 'B' },
          { txt: 'Frame', value: 'F' },
          { txt: 'Masonry Veneer over Frame', value: 'V' },
        ],
        constructionType: '',
        policyDialog: false,
        existingPolicy: false,
        existingPolicyNumber: null,
        startDateDialog: false,
        todaysDate: moment().format('YYYY-MM-DD'),
        maxDate: moment().add(3, 'years').format('YYYY-MM-DD'),
        policy: {
          startDate: moment().format('YYYY-MM-DD'),
        },
        lossComponents: {
          liabilityLosses: {
            clickFieldValues: [
              'propertyInfo liabilityLoss.dateOfLoss',
              'propertyInfo liabilityLoss.amountOfPaid',
              'propertyInfo liabilityLoss.claimStatus',
            ],
            sequenceNumbers: [63, 64, 65],
          },
          waterLosses: {
            clickFieldValues: [
              'propertyInfo waterLoss.dateOfLoss',
              'propertyInfo waterLoss.amountOfPaid',
              'propertyInfo waterLoss.claimStatus',
            ],
            sequenceNumbers: [66, 67, 68],
          },
          applianceLosses: {
            clickFieldValues: [
              'propertyInfo applianceLoss.dateOfLoss',
              'propertyInfo applianceLoss.amountOfPaid',
              'propertyInfo applianceLoss.claimStatus',
            ],
            sequenceNumbers: [69, 70, 71],
          },
          weatherLosses: {
            clickFieldValues: [
              'propertyInfo weatherLoss.dateOfLoss',
              'propertyInfo weatherLoss.amountOfPaid',
              'propertyInfo weatherLoss.claimStatus',
            ],
            sequenceNumbers: [72, 73, 74],
          },
          otherLosses: {
            clickFieldValues: [
              'propertyInfo otherLoss.dateOfLoss',
              'propertyInfo otherLoss.amountOfPaid',
              'propertyInfo otherLoss.claimStatus',
            ],
            sequenceNumbers: [75, 76, 77],
          },
        },
        surveyType: 'Condo Survey v1',
      }
    },
    computed: {
      propertyData () {
        return {
          construction: this.construction,
          bldgProtection: this.bldgProtection,
          bldgImprovements: this.bldgImprovements,
          dwellInspectionValuation: this.dwellInspectionValuation,
          qa: this.questionAnswers,
          solidFuel: this.solidFuel,
          purchaseDt: this.yearPurchased,
          losses: this.recordedLosses,
        }
      },
      formattedSSN () {
        return this.actualSSN.replace(/-/g, '')
      },
      plumbingYears () {
        const years = []
        for (let i = 0; i < 50; i++) {
          years.push(moment().subtract(i, 'years').format('YYYY'))
        }
        return years
      },
      yearBuiltDiff () {
        let date = moment(this.yearBuilt, 'YYYY')
        return moment().diff(date, 'years')
      },
      construction () {
        let construction = {
          constructionCd: this.owner.construction.constructionCd,
          yearBuilt: this.owner.construction.yearBuilt,
          electricalPanelCd: this.q2350,
        }
        if (this.roofType) {
          Object.assign(construction, {
            roofGeometryTypeCd: this.roofType.value,
          })
        }
        if (this.stormShutters) {
          Object.assign(construction, { stormShuttersCd: this.stormShutters })
        }
        return construction
      },
      bldgImprovements () {
        let rec
        if (this.roofUpdated && this.plumbingUpdated) {
          rec = {
            roofingImprovementYear: this.roofUpdated,
            plumbingImprovementYear: this.plumbingUpdated,
          }
        } else if (!this.roofUpdated && this.plumbingUpdated) {
          rec = {
            plumbingImprovementYear: this.plumbingUpdated,
          }
        } else if (this.roofUpdated && !this.plumbingUpdated) {
          rec = {
            roofingImprovementYear: this.roofUpdated,
          }
        } else {
          rec = null
        }
        return rec
      },
      purchaseYears () {
        const years = []
        let diff = moment().diff(
          moment(this.owner.construction.yearBuilt, 'YYYY'),
          'years'
        )
        for (let i = 0; i <= diff; i++) {
          years.push(moment().subtract(i, 'years').format('YYYY'))
        }
        return years
      },
      builtYears () {
        const years = []
        for (let i = 0; i < 200; i++) {
          years.push(moment().subtract(i, 'years').format('YYYY'))
        }
        return years
      },
      lob () {
        return this.$store.getters.getStillwaterLOB
      },
      policyCd () {
        return this.$store.getters.getStillwaterPolicyCd
      },
      questionAnswers () {
        return this.answerList.filter(answer => answer.qCode !== '')
      },
      lossCodes () {
        return this.$store.getters.getStillwaterLossCodes
      },
      amt () {
        return this.quote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
          .HomeLineBusiness[0].Dwell[0].Coverage[0].Limit[0].FormatInteger[0]
      },
      owner () {
        return this.$store.getters.getCondoOwner
      },
      questions () {
        return this.$store.getters.getCondoSurveyQuestions
      },
      discountQuestions () {
        return this.$store.getters.getCondoDiscountQuestions
      },
      loading () {
        return this.$store.getters.getloading
      },
      quote () {
        return this.$store.getters.getCondoQuote
      },
      hurricaneDeductible () {
        let deductible = ''
        if (
          this.quote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
            .HomeLineBusiness[0].Dwell[0].Coverage[5].Deductible[0]
            .FormatInteger[0] !== undefined
        ) {
          deductible = this.quote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
            .HomeLineBusiness[0].Dwell[0].Coverage[5].Deductible[0]
            .FormatInteger[0]
        }
        return deductible
      },
      deductible () {
        return this.quote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
          .HomeLineBusiness[0].Dwell[0].Coverage[1].Deductible[0].FormatInteger[0]
      },
      customQuote () {
        return this.$store.getters.getCustomCondoQuote
      },
      quoteDeductible () {
        return numeral(
          this.quote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
            .HomeLineBusiness[0].Dwell[0].Coverage[1].Deductible[0]
            .FormatInteger[0]
        ).format('0.00')
      },
      customQuoteDeductible () {
        return numeral(
          this.customQuote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
            .HomeLineBusiness[0].Dwell[0].Coverage[1].Deductible[0]
            .FormatInteger[0]
        ).format('0.00')
      },
      customQuotePPR () {
        return numeral(
          this.customQuote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
            .HomeLineBusiness[0].Dwell[0].Coverage[1].Limit[0].FormatInteger[0]
        ).format('0,0.00')
      },
      customQuotePremium () {
        return numeral(
          this.customQuote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
            .PolicySummaryInfo[0].FullTermAmt[0].Amt[0]
        ).format('0.00')
      },
      customQuoteLiability () {
        return numeral(
          this.customQuote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
            .HomeLineBusiness[0].Dwell[0].Coverage[3].Limit[0].FormatInteger[0]
        ).format('0,0.00')
      },
      contentsCoverage () {
        return this.quote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
          .HomeLineBusiness[0].Dwell[0].TotalInsurableReplCostAmt[0].Amt[0]
      },
      dwellInspectionValuation () {
        return {
          totalLivingArea: {
            numUnits: parseInt(this.squareFootage),
          },
        }
      },
      bldgProtection () {
        let protectionDeviceBurglarCd = this.discountAnswers.find(
          answer => answer.jCode === 'protectionDeviceBurglarCd'
        )
        let protectionDeviceFireCd = this.discountAnswers.find(
          answer => answer.jCode === 'protectionDeviceFireCd'
        )
        return this.sprinklerCd
          ? {
            fireExtinguisherInd: this.customDiscountAnswers[0].answer
              ? this.customDiscountAnswers[0].answer
              : 0,
            protectionDeviceSmokeCd: this.customDiscountAnswers[1].answer
              ? this.customDiscountAnswers[1].answer
              : '',
            doorLockCd: this.customDiscountAnswers[2].answer
              ? this.customDiscountAnswers[2].answer
              : '',
            protectionDeviceBurglarCd: protectionDeviceBurglarCd
              ? protectionDeviceBurglarCd.answer
              : '',
            protectionDeviceFireCd: protectionDeviceFireCd
              ? protectionDeviceFireCd.answer
              : '',
            protectionDeviceSprinklerCd: this.sprinklerCd,
            distanceToHydrant: {
              numUnits: parseInt(this.q2057),
              unitMeasurementCd: 'FT',
            },
          }
          : {
            fireExtinguisherInd: this.customDiscountAnswers[0].answer
              ? this.customDiscountAnswers[0].answer
              : 0,
            protectionDeviceSmokeCd: this.customDiscountAnswers[1].answer
              ? this.customDiscountAnswers[1].answer
              : '',
            doorLockCd: this.customDiscountAnswers[2].answer
              ? this.customDiscountAnswers[2].answer
              : '',
            protectionDeviceBurglarCd: protectionDeviceBurglarCd
              ? protectionDeviceBurglarCd.answer
              : '',
            protectionDeviceFireCd: protectionDeviceFireCd
              ? protectionDeviceFireCd.answer
              : '',
            distanceToHydrant: {
              numUnits: parseInt(this.q2057),
              unitMeasurementCd: 'FT',
            },
          }
      },
    },
    created () {
      console.log('owner', this.owner)
      console.log(this.quote)
      console.log(this.questions)
      console.log('discount answers', this.discountAnswers)
    },
    mounted () {
      console.log('loss codes ', this.lossCodes)
    },
    methods: {
      log2057 () {
        console.log('2057', this.q2057)
      },
      handleUnoccupied (val, question) {
        if (val < 12) {
          this.setAnswer(question.qCode, 'NO', question.id)
        } else {
          this.setAnswer(question.qCode, 'YES', question.id)
        }
      },
      logConstruction () {
        console.log('construction type', this.construction)
        console.log('year built diff', this.yearBuiltDiff)
      },
      logBldgProtection () {
        console.log('bldg protection', this.bldgProtection)
      },
      recordLoss (loss) {
        // check if loss is already in recordedLosses array
        let l = this.recordedLosses.find(l => l.id === loss.id)
        if (l) {
          this.recordedLosses.splice(this.recordedLosses.indexOf(l), 1)
          this.recordedLosses.push(loss)
        } else {
          this.recordedLosses.push(loss)
        }
        console.log('RECORDED LOSSES ARRAY', this.recordedLosses)
      },
      searchList (question) {
        if (
          this.answerList.some(
            answer =>
              answer.id === question.dependentRule.conditions[0].qid &&
              answer.answer === question.dependentRule.conditions[0].values[0]
          )
        ) {
          return true
        }
        return false
      },
      setCustomDiscountAnswer (jCode, val, id) {
        console.log('setting custom discount answer', jCode, val, id)
        if (val === 'YES') {
          this.customDiscountAnswers = [
            { jCode: jCode[0], answer: 1, id: id },
            { jCode: jCode[1], answer: 'DE', id: id },
            { jCode: jCode[2], answer: 'DEADB', id: id },
          ]
          console.log(this.customDiscountAnswers)
        } else {
          this.customDiscountAnswers = [
            { jCode: jCode[0], answer: 0, id: id },
            { jCode: jCode[1], answer: 'NO', id: id },
            { jCode: jCode[2], answer: 'NONE', id: id },
          ]
          console.log(this.customDiscountAnswers)
        }
        console.log(this.bldgProtection)
      },
      setDiscountAnswer (jCode, val, id) {
        console.log('setting discount answer', jCode, val, id)
        if (
          this.discountAnswers.length > 0 &&
          this.discountAnswers.find(answer => answer.id === id)
        ) {
          this.discountAnswers = this.discountAnswers.filter(
            answer => answer.id !== id
          )
          this.discountAnswers.push({ jCode: jCode, answer: val, id: id })
        } else {
          this.discountAnswers.push({ jCode: jCode, answer: val, id: id })
        }
        console.log(this.discountAnswers)
      },
      setAnswer (code, val, id) {
        console.log('setting answer')
        if (this.answerList.some(answer => answer.id === id)) {
          // replace answer if it already exists
          this.answerList = this.answerList.filter(answer => answer.id !== id)
          this.answerList.push({ qCode: code, answer: val.toString(), id: id })
        } else {
          this.answerList.push({ qCode: code, answer: val.toString(), id: id })
        }
        console.log(this.answerList)
      },
      closeCoverageIncreaseDialog () {
        this.coverageIncreaseDialog = false
        this.newCoverageAmount = ''
        this.$store.dispatch('clearCustomRentalQuote')
      },
      submitCoverageIncrease () {
        this.$store.dispatch('setCondoQuote', this.customQuote)
        this.$store.dispatch('clearCustomRentalQuote')
        this.coverageIncreaseDialog = false
      },
      getCustomQuote () {
        let owner = this.owner
        this.$store.dispatch('getCustomCondoQuote', {
          deductible: parseInt(this.quoteDeductible),
          amt: parseInt(this.newCoverageAmount),
          type: 'custom',
          owner,
        })
      },
      async next () {
        this.$store.dispatch('setCondoOwnerSSN', this.formattedSSN)

        const isFormValid = await this.$refs.form.validate()
        console.log(isFormValid)

        if (!isFormValid) {
          console.log('Error validating form')
          return
        }

        this.$store.dispatch('setCondoPropertyData', this.propertyData)

        const recordedLossesLength = this.recordedLosses.length
        console.log('calling with', recordedLossesLength === 0 ? 'survey answers' : 'losses')

        const deductible = parseInt(this.deductible.replace(/,/g, ''), 10)
        try {
          await this.$store.dispatch('getCondoQuoteUnderwriting', {
            deductible,
            amt: this.amt,
            owner: this.owner,
            maritalStatus: this.maritalStatus,
          })
          this.$router.push({ path: '/condo/coverages' })
        } catch (error) {
          console.error('Error fetching condo quote underwriting:', error)
        }
      },
      clickedField (value, dataPoint) {
        let userIdHere = 'Visitor'
        if (this.$store.getters.getuser && this.$store.getters.getuser.id) {
          userIdHere = this.$store.getters.getuser.id
        }
        this.$gtag.event('Renters Survey v1', {
          userId: userIdHere,
          dataPoint,
          action: 'Clicked field',
          data: `${value}`,
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
.title-text {
  font-weight: normal;
  font-size: 36px;
  color: #00a1b7;
}
.sub-title {
  font-size: 20px;
  font-weight: 700;
  color: #00a1b7;
}
.plan-title {
  font-size: 30px;
}
.plan-sub {
  font-size: 26px;
}
.selected {
  background-color: #005f6c;
  color: white;
}

::v-deep .v-list-item .v-list-item__title {
  white-space: normal !important;
  overflow-wrap: break-word normal !important;
  word-wrap: break-word normal !important;
  -webkit-hyphens: auto normal !important;
  -moz-hyphens: auto normal !important;
  -webkit-line-clamp: unset !important;
  hyphens: auto normal !important;
}

::v-deep .v-select__selection--comma {
  white-space: normal !important;
  overflow-wrap: break-word normal !important;
  word-wrap: break-word normal !important;
  -webkit-hyphens: auto normal !important;
  -moz-hyphens: auto normal !important;
  -webkit-line-clamp: unset !important;
  hyphens: auto normal !important;
}
</style>
